import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "../../sass/app.scss";
import Loader from "./layout/layoutcomponent/loaders";
import { uiSetEnviromentVersions } from "../redux/slices/UISlice";
import { useDispatch, useSelector } from "react-redux";
import { renewSession } from "../redux/slices/AuthSlice";
import { LoadingApp } from "../components/LoadingApp";
import { useHasPermission } from "../hooks/useHasPermission";
import { PrivateRoute } from "../components/router/PrivateRoute";
import { PublicRoute } from "../components/router/PublicRoute";

const Layout = React.lazy(() => import("./layout/App"));

const Dashboard = React.lazy(() => import("./home/Dashboard"));
const LoginScreen = React.lazy(() => import("./auth/LoginScreen"));
const RecoverPassword = React.lazy(() => import("./auth/RecoverPassword"));
const ChangePassword = React.lazy(() => import("./auth/ChangePassword"));

const MembershipScreen = React.lazy(() => import("./operator/memberships/MembershipScreen"));
const UserScreen = React.lazy(() => import("./operator/users/UserScreen"));
const UserDetail = React.lazy(() => import("./operator/users/UserDetail"));

export const AppRouter = () => {
    const { checking, user } = useSelector((store) => store.auth);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(uiSetEnviromentVersions());
        dispatch(renewSession());
    }, []);

    if (!checking) {
        return <Loader />;
    }

    return (
        <BrowserRouter>
            <React.Suspense fallback={<Loader />}>
                <Routes>
                    <Route
                        path={`/login`}
                        element={<PublicRoute component={LoginScreen} />}
                    />
                    <Route
                        path={`/recuperar/contraseña`}
                        element={<PublicRoute component={RecoverPassword} />}
                    />
                    <Route
                        path={`/cambiar/contraseña`}
                        element={<PublicRoute component={ChangePassword} />}
                    />

                    <Route path={"/"} element={<Layout />}>
                        <Route
                            path={`/app/inicio`}
                            element={<PrivateRoute component={Dashboard}  />}
                        />
                        <Route
                            path={`/app/membresias`}
                            element={<PrivateRoute component={MembershipScreen}/>}
                        />
                        <Route
                            path={`/app/administradores`}
                            element={<PrivateRoute component={UserScreen} permissions={["Administrador"]} />}
                        />
                        <Route
                            path={`/app/administrador/:user_id`}
                            element={<PrivateRoute component={UserDetail}  permissions={["Administrador"]} />}
                        />

                        {/* Default */}
                        <Route
                            path={`/`}
                            element={<PrivateRoute component={Dashboard} />}
                        />
                    </Route>
                    <Route
                        path="/*"
                        element={<Navigate to={"/app/inicio"} />}
                    />
                </Routes>
            </React.Suspense>
            <LoadingApp />
        </BrowserRouter>
    );
};
