import { createSlice } from "@reduxjs/toolkit";
import { startUILoading, stopUILoading } from "./UISlice";

const initialState = {
    darkMode: !!localStorage.getItem("nowadark"),
};

const DarkMode = createSlice({
    name: "DarkMode",
    initialState,
    reducers: {
        setDarkMode: (state, action) => {
            state.darkMode = action.payload;
        },
    },
});

export const { setDarkMode } = DarkMode.actions;

export default DarkMode.reducer;

export const toggleDarkMode = (value) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        dispatch(setDarkMode(value));

        dispatch(stopUILoading());
    };
};
