import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { AppRouter } from "./views/AppRouter";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <AppRouter />
    </Provider>
);
