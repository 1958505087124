import { createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { Call, cleanEmpty } from "../../../helpers/fetch";
import { startUILoading, stopUILoading } from "../UISlice";
import { errorHandler } from "../../../helpers/errorHandler";
import { deleteConfirmModal } from "../../../helpers/DeleteValidate";

const initialState = {
    countries: [],
    states: [],
};

const CataloguesSlice = createSlice({
    name: "CataloguesSlice",
    initialState,
    reducers: {
        setCountries: (state, action) => {
            state.countries = action.payload;
        },
        setStates: (state, action) => {
            state.states = action.payload;
        },
    },
});

export const { setCountries, setStates } = CataloguesSlice.actions;

export default CataloguesSlice.reducer;

export const startGetCountries = (setErrors) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(`operators/catalogues/countries`, "GET");
        if (resp.success) {
            dispatch(setCountries(resp.success));
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startGetStates = (setErrors) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(`operators/catalogues/states`, "GET");
        if (resp.success) {
            dispatch(setStates(resp.success));
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};
