import PropTypes from "prop-types";
import { Suspense } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { SuspenceFallbackContainer } from "./SuspenceFallbackContainer";
import { useHasPermission } from "../../hooks/useHasPermission";

export const PublicRoute = ({ component: Component}) => {
    const { isLogged } = useHasPermission();

    return isLogged ? (
        <Navigate to={"/app/inicio"} />
    ) : (
        <Component />
    );
};

PublicRoute.propTypes = {
    component: PropTypes.object.isRequired,
};
