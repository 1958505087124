import Swal from "sweetalert2";

export const deleteConfirmModal = async (callback = () => {}) => {
    const result = await Swal.fire({
        title: "¿Está seguro de eliminar?",
        icon: "warning",
        text: "¡No podrá revertir esta acción!",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "¡Sí, eliminar!",
        confirmButtonColor: "#2e4a6c",
        cancelButtonColor: "#6c757d",
        input: "text",
        inputLabel:
            "Escriba la palabra 'eliminar' para confirmar esta operación.",
        inputAttributes: {
            autocapitalize: "off",
        },
        allowOutsideClick: false,
        allowEnterKey: false,
    });

    if (result.value === "eliminar") {
        await callback();
    } else if (result.value) {
        Swal.fire("Operación cancelada", "", "info");
    }
};
