import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export const PrivateRoute = ({ component: Component, permissions = null }) => {
    const { user } = useSelector((state) => state.auth);

 
    const isAccesible = () => {
        if (permissions === null) {
            return true;
        }

        return permissions.includes(user.role);
    };

    return user ? (
        <>{isAccesible() ? <Component /> : <Navigate to={"/login"} />}</>
    ) : (
        <Navigate to={"/login"} />
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.object.isRequired,
    permissions: PropTypes.any,
};
