import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tiny_name: "",
    ...JSON.parse(root.getAttribute("configs")),
};
const ConfigSlice = createSlice({
    name: "ConfigSlice",
    initialState,
    reducers: {
        setConfig: (state) => {
            state = state.payload;
        },
    },
});
export const { setConfig } = ConfigSlice.actions;

export default ConfigSlice.reducer;
