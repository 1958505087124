import { createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import {
    Call,
    CallWithFormData,
    cleanEmpty,
    objectToFormData,
} from "../../../helpers/fetch";
import { startUILoading, stopUILoading } from "../UISlice";
import { errorHandler } from "../../../helpers/errorHandler";
import { deleteConfirmModal } from "../../../helpers/DeleteValidate";
import { renewSession } from "../AuthSlice";

const initialState = {
    pagination: {
        data: [],
        per_page: 10,
    },
    active: null,
};

const UserSlice = createSlice({
    name: "UserSlice",
    initialState,
    reducers: {
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setActive: (state, action) => {
            state.active = action.payload;
        },
        unsetActive: (state, action) => {
            state.active = null;
        },
    },
});

export const { setPagination, setActive, unsetActive } = UserSlice.actions;

export default UserSlice.reducer;

export const show = (values) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const params = new URLSearchParams();

        params.set("page", values.page);
        params.set("q", values.query);
        params.set("per_page", values.number_rows);

        const resp = await Call(
            `operators/users/show`,
            "GET",
            params.toString()
        );
        if (resp.success) {
            dispatch(setPagination(resp.success));
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const find = (user_id, navigate) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(`operators/users/find/${user_id}`, "GET");
        if (resp.success) {
            dispatch(setActive(resp.success));
        } else {
            navigate("/");
        }
        dispatch(stopUILoading());
    };
};

export const create = (
    values,
    onSubmit /* `setErrors` is a function that is used to set validation
errors in the form fields when there is an error response
from the API during the create or edit operations. It is
typically used to display error messages to the user
indicating what went wrong with their input data. */,
    setErrors
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await CallWithFormData(
            `operators/users/create`,
            "POST",
            objectToFormData(values)
        );
        if (resp.success) {
            onSubmit();
            Swal.fire({
                title: "Registro exitoso",
                text: "Registro exitoso.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};
export const edit = (id, values, setErrors, CrudData) => {
    return async (dispatch, getState) => {
        dispatch(startUILoading());

        if (!values.password || !values.password_confirmation) {
            delete values.password;
            delete values.password_confirmation;
        }

        const resp = await Call(
            `operators/users/edit/${id}`,
            "POST",
            cleanEmpty(values)
        );

        if (resp.success) {
            const user = getState().auth.user;

            if (user.id == resp.success.id) {
                dispatch(renewSession());
            }
            
            dispatch(show(CrudData));
            Swal.fire({
                title: "Modificación exitosa",
                text: "Modificación exitosa.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const destroy = (user_id, onDelete = () => {}) => {
    return async (dispatch) => {
        await deleteConfirmModal(async () => {
            dispatch(startUILoading());
            const resp = await Call(
                `operators/users/delete/${user_id}`,
                "DELETE"
            );

            if (resp.success) {
                onDelete();
                Swal.fire({
                    title: "Eliminación exitosa",
                    text: "Eliminación exitosa.",
                    icon: "success",
                    toast: true,
                    position: "bottom-end",
                    timer: 8000,
                });
            } else {
                errorHandler(resp);
            }
            dispatch(stopUILoading());
        });
    };
};
