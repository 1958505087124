import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    php: "V. X.X",
    laravel: "V. X.X",
    notifications: [],
};

const UISlice = createSlice({
    name: "UISlice",
    initialState,
    reducers: {
        startUILoading: (state) => {
            state.loading = true;
        },
        stopUILoading: (state) => {
            state.loading = false;
        },
        uiSetEnviromentVersions: (state) => {
            const root = document.getElementById("root");
            state.php = root.getAttribute("php");
            state.laravel = root.getAttribute("laravel");
        },
    },
});
export const { startUILoading, stopUILoading, uiSetEnviromentVersions } =
    UISlice.actions;

export default UISlice.reducer;
