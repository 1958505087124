import {
    applyMiddleware,
    combineReducers,
    compose,
    legacy_createStore as createStore,
} from "redux";
import thunk from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";

import AuthSlice from "./slices/AuthSlice";
import UISlice from "./slices/UISlice";
import ConfigSlice from "./slices/ConfigSlice";
import UserSlice from "./slices/operator/UserSlice";
import CataloguesSlice from "./slices/catalogues/CataloguesSlice";
import DarkMode from "./slices/DarkMode";
import MembershipSlice from "./slices/operator/MembershipSlice";

const rootReducer = combineReducers({
    auth: AuthSlice,
    ui: UISlice,
    config: ConfigSlice,
    users: UserSlice,
    catalogues: CataloguesSlice,
    colorMode: DarkMode,
    membership: MembershipSlice
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk],
});
